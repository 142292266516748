import React from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core'

import NextButton from 'components/lib/NextButton'
import MultipleChoiceBlock from 'components/Survey/Blocks/MultipleChoiceBlock'
import { LanguageType } from 'generated/graphql'
import { useTranslations, TranslationKey, LanguageContext } from 'locales'
import { desktopStyle } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      height: '100%',
      width: '100%',
    },
    body: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      padding: '5%',
      '& >h4': {
        marginTop: '5%',
        color: theme.palette.common.white,
      },
      ...desktopStyle({
        paddingLeft: '20%',
        paddingRight: '20%',
      }),
    },
    subtitle: {
      color: theme.palette.common.white,
      marginTop: '12%',
      ...desktopStyle({
        position: 'fixed',
      }),
    },
  }),
)

interface Props {
  goNext(): void
  languages: LanguageType[]
}

const SelectLanguageScreen: React.FC<Props> = ({ goNext, languages }) => {
  const classes = useStyles()

  const { t } = useTranslations()

  const question = {
    text: `${t('Please select your language')}:`,
    benchmarkCode: 'language',
  }
  return (
    <LanguageContext.Consumer>
      {({ changeLanguageCode, languageCode }) => (
        <div className={classes.container}>
          <div className={classes.body}>
            <MultipleChoiceBlock
              question={question}
              onChange={changeLanguageCode}
              displayHorizontal={false}
              isMultiselect={false}
              choices={languages.map(l => ({
                code: l.code,
                text: l.nameTranslated as TranslationKey, // the language name is already translated, doing the cast to appease typescript.
              }))}
              response={languageCode}
            />
            <NextButton
              color="primary"
              nextLabel="Save and Continue"
              goNext={goNext}
              useSmallButton={false}
            />
          </div>
        </div>
      )}
    </LanguageContext.Consumer>
  )
}

export default SelectLanguageScreen
