import React, { ReactElement } from 'react'

import { Typography, makeStyles, Button, Theme, createStyles } from '@material-ui/core'
import cn from 'classnames'

import useGlobalStyles from 'components/lib/GlobalStyles'
import { useTranslations, TranslationKey } from 'locales'
import { desktopStyle } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: theme.palette.common.white,
      height: '100%',
      background: theme.palette.common.brandBlue,
      '& >h4': {
        marginTop: '20%',
      },
      paddingLeft: '5%',
      paddingRight: '5%',
      ...desktopStyle({
        paddingLeft: '25%',
        paddingRight: '25%',
      }),
    },
    skipButton: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
      ...desktopStyle({
        textAlign: 'left',
      }),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    footer: {
      marginTop: theme.spacing(10),
      fontSize: '2.3rem',
    },
  }),
)

interface Props {
  questionId?: null | string
  label: string | ReactElement
  nextLabel?: TranslationKey
  goNext(): void
  buttonGroupStyle?: string
  // Optionally display a "No" option for the transition, with its own onClick handler
  skipLabel?: TranslationKey
  onSkip?(): void
  footerNote?: TranslationKey
}

const TransitionBlock: React.FC<Props> = ({
  questionId,
  label,
  nextLabel,
  goNext,
  skipLabel,
  onSkip,
  buttonGroupStyle,
  footerNote,
}) => {
  const classes = { ...useStyles(), ...useGlobalStyles() }
  const { t } = useTranslations()

  return (
    <div className={classes.container}>
      <Typography variant="h4" id={questionId || ''}>
        {label}
      </Typography>
      <div className={cn(classes.footerButton, buttonGroupStyle)}>
        <Button variant="text" color="secondary" onClick={goNext}>
          {(nextLabel && t(nextLabel)) || t('Continue')}
        </Button>
        {skipLabel && onSkip && (
          <div className={classes.skipButton} onClick={onSkip} aria-hidden="true">
            <u>{t(skipLabel)}</u>
          </div>
        )}
      </div>
      {footerNote && <div className={classes.footer}>{t(footerNote)}</div>}
    </div>
  )
}

export default TransitionBlock
