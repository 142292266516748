import React from 'react'

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'

import App from 'App'
import { RELEASE_VERSION, SENTRY_DSN } from 'config/'
import 'index.css'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: RELEASE_VERSION,
    dist: 'survey',
    beforeBreadcrumb: breadcrumb => {
      if (breadcrumb.category === 'xhr' && breadcrumb?.data?.url) {
        // Throw away XHR breadcrumbs that are related to:
        // - GraphQL fetches (we're logging breadcrumbs separately with the apollo-link library)
        // - Requests to google analytics; these are never useful for debugging.
        const url = new URL(breadcrumb?.data?.url)
        if (url.pathname === '/graphql/' || url.host === 'www.google-analytics.com') {
          return null
        }
      }
      return breadcrumb
    },
  })
  window.Sentry = Sentry
}

ReactDOM.render(<App />, document.getElementById('root'))
