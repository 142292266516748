import React, { SyntheticEvent, useEffect, useState } from 'react'

import { TextField, TextFieldProps } from '@material-ui/core'
import debounce from 'lodash.debounce'

type Props = {
  delayTime?: number
  InputProps?: object
  onUpdate(response: string): void
} & TextFieldProps

const DebouncedTextInput: React.FC<Props> = ({
  onUpdate,
  delayTime,
  value: initialValue,
  ...restProps
}) => {
  const [value, setValue] = useState(initialValue)
  const [debouncer, setDebouncer] = useState(() => debounce(onUpdate, delayTime || 1000))

  useEffect(() => {
    setDebouncer(() => debounce(onUpdate, delayTime || 1000))
  }, [onUpdate, delayTime])

  const onChange = (e: SyntheticEvent) => {
    const { value: textValue } = e.target as HTMLInputElement
    setValue(textValue)
    debouncer(textValue)
  }

  return <TextField value={value} onChange={onChange} {...restProps} />
}

export default DebouncedTextInput
