import React from 'react'

import {
  makeStyles,
  useMediaQuery,
  useTheme,
  Avatar,
  FormControl,
  NativeSelect,
  Theme,
} from '@material-ui/core'
import cn from 'classnames'

import Label from 'components/Survey/Blocks/Label'
import { TranslatedQuestion } from 'components/Survey/Survey'
import { useTranslations, TranslationKey } from 'locales'

interface MobileProps {
  text: string
  choices: NonNullable<TranslatedQuestion['choices']>
  onChange: (response: string) => void
  response?: string
}
const MobileLinearBlock: React.FC<MobileProps> = ({ text, choices, onChange, response }) => {
  const { t } = useTranslations()

  return (
    <>
      <Label text={text} />
      <FormControl variant="filled">
        <NativeSelect
          value={response}
          name="survey"
          onChange={e => onChange((e.target as HTMLSelectElement).value)}
        >
          <option aria-label="None" value="">
            {t('Please choose one')}
          </option>
          {choices.map(choice => {
            let label = `${choice.value}`
            if (choice.text) {
              label += ` - ${t(choice.text as TranslationKey)}`
            }
            return (
              <option key={choice.value!} value={choice.value!}>
                {label}
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  scale: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  scaleItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(),
  },
  choice: {
    margin: theme.spacing(),
    border: `1px ${theme.palette.common.brandBlue} solid`,
  },
  selected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.brandBlue,
    '&:hover': {
      backgroundColor: theme.palette.common.brandBlue,
    },
  },
  label: {
    color: theme.palette.common.brandBlue,
  },
}))

interface Props {
  question: TranslatedQuestion
  onChange: (response: string) => void
  response?: string
}
const LinearBlock: React.FC<Props> = ({ question, onChange, response }) => {
  const classes = useStyles()
  const { t } = useTranslations()
  const theme = useTheme()
  // Mobile and desktop will display differently. One one is shown, the other should be hidden
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { choices } = question
  if (!choices) return <></>
  const scaleLength = choices.length
  let avatarSize = 80
  if (scaleLength > 9) {
    avatarSize = 40
  } else if (scaleLength > 5) {
    avatarSize = 60
  }
  // When the linear scale is too big, it won't fit in the width set for the entire app somewhere at the root. It will go more to the right
  // So what we want to do is give labels a fixed width determined by the width of the scale choices so that they get horizontally aligned at the right with the circles.
  const scaleLabelsWidth = scaleLength * (avatarSize + theme.spacing() * 2)
  return isMobile ? (
    <MobileLinearBlock
      key={question.code}
      text={question.text}
      choices={choices}
      onChange={onChange}
      response={response}
    />
  ) : (
    <div style={{ width: '100%' }}>
      <Label text={question.text} id={question.benchmarkCode} />
      {/* we use minWidth when there are too few labels and we want to make sure that they take up at least the same amount of space as the other question blocks */}
      <div style={{ width: scaleLabelsWidth, minWidth: '100%' }}>
        <div className={classes.scale}>
          {choices.map(choice => {
            const value = choice.value!
            const selected = response === String(value)
            return (
              <div className={classes.scaleItem} key={value}>
                <Avatar
                  id={`answer${value}${selected ? '-selected' : ''}`}
                  className={cn(classes.choice, {
                    [classes.selected]: selected,
                  })}
                  style={{ width: avatarSize, height: avatarSize }}
                  onClick={() => onChange(String(value))}
                >
                  {value}
                </Avatar>
                {t(choice.text as TranslationKey)}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default LinearBlock
