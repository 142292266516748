import React from 'react'

import { withStyles, Typography, WithStyles, createStyles, Theme } from '@material-ui/core'

import { desktopStyle } from 'utils'

const styles = (theme: Theme) =>
  createStyles({
    label: {
      position: 'relative',
      marginBottom: theme.spacing(3),
      ...desktopStyle({
        fontSize: '2.4rem',
      }),
    },
  })

interface Props extends WithStyles<typeof styles> {
  id?: null | string
  text: string
}

const addLineBreaks = (text: string) =>
  text.split('\n').map((line: string, index: number) => (
    <React.Fragment key={`${line}-${index}`}>
      {line}
      <br />
    </React.Fragment>
  ))

const Label: React.FC<Props> = ({ id, text, classes }) => {
  return (
    <Typography id={id || ''} className={classes.label}>
      {addLineBreaks(text)}
    </Typography>
  )
}

export default withStyles(styles)(Label)
