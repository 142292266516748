import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

import { desktopStyle } from 'utils'

const fontFamily =
  '"YogaSans","Lucida Grande","Lucida Sans Unicode","Lucida Sans","Helvetica", "Arial", sans-serif'

const darkGrey = 'rgba(0, 0, 0, 0.81)'
const mediumGrey = 'rgba(0, 0, 0, 0.5)'
const lightGrey = 'rgba(0, 0, 0, 0.15)'
const navy65 = 'rgba(15, 50, 70, 0.65)'
const white = '#FFF'
const commonColors = {
  brandBlue: '#1B46B8',
  lightBlue: '#EFF4FF',
  mediumBlue: '#134DCF',
  darkBlue: '#002484',
  disabledBlue: '#A4B5E3',
  fadedBlue: '#D0D8ED',
  fadedGrey: '#EBEDF1',
  white,
  lightGrey,
  mediumGrey,
  darkGrey,
  navy65,
  error: '#EF585A',
}

export type CommonColorsAlias = typeof commonColors

// Module extension to allow accessing our custom properties on the theme object.
declare module '@material-ui/core/colors/common' {
  interface CommonColors extends CommonColorsAlias {}
}

const themeOptions: ThemeOptions = {
  typography: {
    button: {
      fontSize: '1.5rem',
      ...desktopStyle({
        fontSize: '1.8rem',
      }),
      textTransform: 'initial',
    },
    fontFamily,
    htmlFontSize: 10,
    fontSize: 18,
    h1: {
      fontSize: '4.2rem',
      fontFamily: 'YogaSansBold',
    },
    h2: {
      fontSize: '3.6rem',
      fontFamily: 'YogaSansBold',
    },
    h3: {
      fontSize: '2.8rem',
      fontFamily: 'YogaSansBold',
    },
    h4: {
      fontSize: '2.4rem',
      fontFamily: 'YogaSansBold',
      ...desktopStyle({
        fontSize: '3.6rem',
      }),
    },
    h5: {
      fontSize: '2.0rem',
      fontFamily: 'YogaSansBold',
    },
    h6: {
      fontSize: '1.6rem',
      fontFamily: 'YogaSansBold',
    },
    body1: {
      fontSize: '1.8rem',
    },
    body2: {
      fontSize: '1.6rem',
      ...desktopStyle({
        fontSize: '1.8rem',
      }),
    },
    caption: {
      fontSize: '1.2rem',
    },
    subtitle1: {
      fontSize: '1.4rem',
    },
  },
  palette: {
    background: {
      default: white,
    },
    text: {
      primary: darkGrey,
    },
    common: commonColors,
    primary: {
      light: darkGrey,
      main: darkGrey,
      dark: darkGrey,
    },
    secondary: {
      light: mediumGrey,
      main: mediumGrey,
      dark: mediumGrey,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        fontSize: '1.8rem',
      },
      underline: {
        '&:after': {
          borderBottomColor: commonColors.brandBlue,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: commonColors.brandBlue,
        },
      },
    },
    MuiButton: {
      sizeLarge: {
        minHeight: 44,
      },
      textPrimary: {
        '&.Mui-disabled': {
          color: commonColors.white,
        },
        width: 95,
        minHeight: 38,
        color: commonColors.white,
        backgroundColor: commonColors.brandBlue,
        '&:hover': {
          backgroundColor: commonColors.darkBlue,
          // Use the original colors on mobile hover
          '@media (hover: none)': {
            backgroundColor: commonColors.brandBlue,
          },
        },
      },
      textSecondary: {
        minHeight: 38,
        color: commonColors.brandBlue,
        backgroundColor: commonColors.lightBlue,
        '&:hover': {
          backgroundColor: commonColors.lightBlue,
          opacity: 0.75,
          // Use the original colors on mobile hover
          '@media (hover: none)': {
            backgroundColor: commonColors.lightBlue,
          },
        },
        border: `1px solid ${commonColors.brandBlue}`,
      },
      outlinedPrimary: {
        backgroundColor: commonColors.darkBlue,
        color: commonColors.white,
        '&:hover': {
          // Use the original colors on mobile hover
          '@media (hover: none)': {
            backgroundColor: commonColors.darkBlue,
          },
        },
        padding: 6,
      },
    },
    MuiAvatar: {
      colorDefault: {
        borderColor: commonColors.brandBlue,
        color: commonColors.brandBlue,
        backgroundColor: commonColors.fadedGrey,
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: commonColors.fadedBlue,
        },
      },
    },
    MuiRadio: {
      root: {
        color: commonColors.brandBlue,
      },
      colorSecondary: {
        color: commonColors.brandBlue,
        '&$checked': {
          color: commonColors.brandBlue,
        },
      },
    },
  },
}

const theme = createMuiTheme(themeOptions)

export default theme
