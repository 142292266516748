import { useEffect, useState } from 'react'

import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import fetch from 'unfetch'

import {
  QuestionResponse,
  useSaveResponsesMutation,
  ParticipantSourceTypeInput,
} from 'generated/graphql'

const TS_SEPERATOR = '::--::--::'
const getResponsePrefix = (participantId: string) => `response-${participantId}-`

const getLocalStorageResponses = (participantId: string) => {
  const responses: { [key: string]: string } = {}
  const prefix = getResponsePrefix(participantId)
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith(prefix)) {
      const result = localStorage.getItem(key)
      if (!result) return
      responses[key.split(prefix)[1]] = JSON.parse(result)
    }
  })
  return responses
}

// Save responses to local storage in case of network connection issues so we can resubmit
// when connection is reestablished.
export const saveResponseToLocalStorage = (
  responseVal: string,
  code: string,
  participantId: string,
) => {
  // Store timestamp along with response value to keep data structure simple and backward compatible
  const val = `${new Date().getTime()}${TS_SEPERATOR}${responseVal}`
  // Store responses at individual keys instead of a single object to avoid race conditions.
  localStorage.setItem(`${getResponsePrefix(participantId)}${code}`, JSON.stringify(val))
}

// Adapted from: https://medium.com/backticks-tildes/a-guide-to-handling-internet-disconnection-in-react-applications-5fd02eccb2bb
export const useOfflineResponseHandler = (
  participantId?: string,
  source?: ParticipantSourceTypeInput,
) => {
  const [saveResponses] = useSaveResponsesMutation()
  const { data: visitorData } = useVisitorData()
  const fingerprint = visitorData?.visitorId || ''

  const onReconnection = () => {
    if (!participantId) return
    const responses = getLocalStorageResponses(participantId)
    saveResponses({
      variables: {
        participantId,
        submitted: false,
        responses: Object.keys(responses).map(code => {
          const resp: QuestionResponse = {
            code,
            response: responses[code],
          }
          if (resp.response?.includes(TS_SEPERATOR)) {
            const [ts, val] = resp.response.split(TS_SEPERATOR)
            resp.timestamp = new Date(parseInt(ts, 10))
            resp.response = val
          }
          return resp
        }),
        fingerprint,
        source,
      },
    })
  }
  const [isDisconnected, setIsDisconnected] = useState(false)
  const handleConnectionChange = () => {
    // The navigator.onLine property is not fool proof.
    // One concern is that a machine can be connected to a network or a virtual one and not have internet access.
    // A more thorough check is to ping a service that is always online.
    const condition = navigator.onLine ? 'online' : 'offline'
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            setIsDisconnected(prevStateDisconnected => {
              if (prevStateDisconnected) {
                onReconnection()
              }
              clearInterval(webPing)
              return false
            })
          })
          .catch(() => {
            setIsDisconnected(true)
          })
      }, 2000)
      return
    }
    setIsDisconnected(true)
  }
  useEffect(() => {
    window.addEventListener('online', handleConnectionChange)
    window.addEventListener('offline', handleConnectionChange)
    return () => {
      window.removeEventListener('online', handleConnectionChange)
      window.removeEventListener('offline', handleConnectionChange)
    }
  })
  return [isDisconnected]
}
