import React from 'react'

import { Typography, makeStyles, createStyles, Theme } from '@material-ui/core'
import DOMPurify from 'dompurify'

import NavBar from 'components/lib/NavBar'
import NextButton from 'components/lib/NextButton'
import { WelcomeMessage, SurveyTypeEnum, LanguageType } from 'generated/graphql'
import { useTranslations, TranslationKey } from 'locales'
import { desktopStyle, renderTemplate } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      height: '100%',
      width: '100%',
    },
    header: {
      padding: 20,
      height: '30%',
      color: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      '& >h1': {
        marginBottom: theme.spacing(1),
      },
    },
    body: {
      background: 'linear-gradient(103.18deg, #3F7FDF 12.63%, #1B46B8 94.41%)',
      position: 'fixed',
      width: '100%',
      overflow: 'auto',
      maxHeight: '70%',
      padding: '5%',
      '& >h4': {
        marginTop: '5%',
        color: theme.palette.common.white,
      },
      ...desktopStyle({
        paddingLeft: '20%',
        paddingRight: '20%',
      }),
    },
    subtitle: {
      color: theme.palette.common.white,
      marginTop: '12%',
      ...desktopStyle({
        position: 'fixed',
      }),
    },
  }),
)

interface Props {
  goBack?(): void
  goNext(): void
  surveyType: SurveyTypeEnum
  welcomeMessage: WelcomeMessage
  languages: LanguageType[]
  templates: { [key: string]: string }
}

const WelcomeScreen: React.FC<Props> = ({
  goBack,
  goNext,
  surveyType,
  welcomeMessage,
  languages,
  templates,
}) => {
  const classes = useStyles()
  const { t } = useTranslations()
  // Sanitize the welcome message because we are injecting it directly into HTML
  const sanitizedWelcomeMessage = DOMPurify.sanitize(welcomeMessage.text)
  return (
    <div className={classes.container}>
      {languages.length > 1 && goBack && <NavBar goBack={goBack} languages={languages} />}
      <div className={classes.header}>
        <Typography variant="h1" color="primary">
          {renderTemplate(t(welcomeMessage.header as TranslationKey), templates)}
        </Typography>
        <Typography variant="h5" color="secondary">
          {renderTemplate(t(welcomeMessage.subheader as TranslationKey), templates)}
        </Typography>
      </div>
      <div className={classes.body}>
        <Typography variant="h4">
          <div
            dangerouslySetInnerHTML={{
              __html: renderTemplate(t(sanitizedWelcomeMessage as TranslationKey), templates),
            }}
          />
        </Typography>
        {surveyType !== SurveyTypeEnum.RESIDENT_CUSTOM && (
          <Typography variant="subtitle1" className={classes.subtitle}>
            {t('Survey will take 5 - 10 minutes.')}
          </Typography>
        )}
        <NextButton color="secondary" goNext={goNext} />
      </div>
    </div>
  )
}

export default WelcomeScreen
