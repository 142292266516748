import React from 'react'

import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core'

import DebouncedTextInput from 'components/lib/DebouncedTextInput'
import Label from 'components/Survey/Blocks/Label'
import { TranslatedQuestion } from 'components/Survey/Survey'
import { useTranslations, TranslationKey } from 'locales'
import { desktopStyle } from 'utils'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: 20,
      overflowY: 'scroll',
      maxHeight: 270,
      width: '100%',
      ...desktopStyle({
        maxHeight: 320,
      }),
    },
    subtext: {
      marginTop: 20,
      color: theme.palette.common.navy65,
    },
  }),
)

interface Props {
  question: TranslatedQuestion
  onChange: (response: string) => void
  response?: string
  multiline: boolean
  subtext?: TranslationKey
}

const ShortAnswerBlock: React.FC<Props> = ({
  question,
  onChange,
  response,
  multiline,
  subtext,
}) => {
  const classes = useStyles()
  const { t } = useTranslations()

  return (
    <>
      <Label text={question.text} id={question.benchmarkCode} />
      <DebouncedTextInput
        key={question.code}
        value={response}
        multiline={multiline}
        name={question.text || ''}
        className={classes.textField}
        placeholder={t('Type your answer here')}
        onUpdate={onChange}
      />
      {subtext && (
        <Typography className={classes.subtext} color="textSecondary">
          {t(subtext)}
        </Typography>
      )}
    </>
  )
}

export default ShortAnswerBlock
