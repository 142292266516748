import React from 'react'

import { makeStyles, Dialog, createStyles, Theme, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { ReactComponent as Icon } from 'assets/img/customer-alert-icon.svg'

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    container: {
      background: palette.common.brandBlue,
      paddingBottom: spacing(10),
      paddingTop: spacing(10),
      paddingLeft: '18%',
      paddingRight: '18%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '& >svg': {
        marginTop: spacing(3),
        marginBottom: spacing(3),
      },
      '& >h4, >p': {
        color: palette.common.white,
      },
      '& >p': {
        fontSize: '2.0rem',
      },
    },
    closeButton: {
      position: 'absolute',
      right: spacing(),
      top: 0,
      color: palette.common.white,
      '& >svg': {
        height: 22,
      },
    },
  }),
)

interface Props {
  title: string
  description: string
  onClose(): void
}

const AlertModal: React.FC<Props> = ({ title, description, onClose }) => {
  const classes = useStyles()
  return (
    <Dialog fullWidth maxWidth="md" open>
      <div className={classes.container}>
        <Typography variant="h4">{title}</Typography>
        <Icon />
        <Typography>{description}</Typography>
        <IconButton onClick={onClose} id="close-alert" className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>
    </Dialog>
  )
}

export default AlertModal
