import React from 'react'

import i18next from 'i18next'
import fetch from 'unfetch'

import { STATIC_URL } from 'config'
import { DEFAULT_LANGUAGE, SURVEY_NAMEPSACE } from 'locales/constants'

export const LanguageContext = React.createContext({
  languageCode: '',
  // eslint-disable-next-line no-unused-vars
  changeLanguageCode: (languageCode: string) => {},
})

export const generateI18n = (languageCodes: string[]) => {
  const options = {
    debug: false,
    saveMissing: false,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    defaultNS: SURVEY_NAMEPSACE,
    keySeparator: '~',
    nsSeparator: '::',
    backend: {},
  }
  i18next.init(options)

  languageCodes.forEach(languageCode => {
    fetch(`${STATIC_URL}translations/${languageCode}.json`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        i18next.addResourceBundle(languageCode, SURVEY_NAMEPSACE, data)
      })
      .catch(err => {
        console.error(err)
      })
  })

  return i18next
}
