import { CSSProperties } from '@material-ui/core/styles/withStyles'

import { SURVEY_HOST, RESIDENT_SURVEY_HOST } from 'config'
import { SurveyProductTypeEnum } from 'generated/graphql'

const DESKTOP_MEDIA_QUERY = '(min-width: 700px) and (min-height: 600px)'
export const desktopStyle = (style: CSSProperties) => ({
  [`@media ${DESKTOP_MEDIA_QUERY}`]: style,
})

// Replaces templates of form {{key}} with the string in templates[key]
export const renderTemplate = (text: string, templates: { [key: string]: string }) => {
  const regex = /\{\{(.*?)\}\}/
  let match = regex.exec(text)
  while (match) {
    // eslint-disable-next-line
    text = text.replace(match[0], templates[match[1]])
    match = regex.exec(text)
  }
  return text
}

// Returns the given URL without the last path (ex. /survey/take => /survey)
export const getUrlPrefix = (url: string) => url.split('/').slice(0, -1).join('/')

export const getCompletionPercentageWithinRange = (
  questionIndex: number,
  questionsCount: number,
  start: number,
  end: number,
) => {
  const completionPercentage = questionIndex / questionsCount
  return start + Math.round((end - start) * completionPercentage)
}

export const getProductTypeFromUrl = () => {
  if (window.location.origin === SURVEY_HOST) {
    return SurveyProductTypeEnum.EMPLOYEE
  }
  if (window.location.origin === RESIDENT_SURVEY_HOST) {
    return SurveyProductTypeEnum.RESIDENT
  }
  throw new Error(`Could not determine product type from URL: ${window.location.origin}`)
}

export const isDesktopBrowser = () => {
  return window.matchMedia(DESKTOP_MEDIA_QUERY).matches
}
