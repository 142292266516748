import React from 'react'

type Props = {
  to: string
  label: string
}

const ExternalNavLink: React.FC<Props> = ({ to, label }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={to}>
      {label}
    </a>
  )
}

export default ExternalNavLink
