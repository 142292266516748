import { UseTranslationOptions, useTranslation } from 'react-i18next'

import { TranslationFragment } from 'generated/graphql'
import { SURVEY_NAMEPSACE } from 'locales/constants'

export type TranslationKey = keyof typeof import('translations/es.json')
type TranslationFn = (key: TranslationKey) => string
/**
 * In order to enforce that translations stay up to date, we need to make sure that
 *
 * 1. When new languages are added, every necessary string is translated. This is enforced by having
 *    a static set of strings on the backend, where each string has a translation
 *    in every available language. See code in `sync_translations`.
 * 2. Strings used on the frontend stay in sync with the translations provided by the backend.
 *    This is enforced with the following custom hook, by validating that each key used by the `t`
 *    library is called with one of the keys from the static translation files.
 *    Note: we only need to check one language file because the backend is enforcing that each language
 *    has all keys. To see how the static translation file is injected into the frontend, see Dockerfile.
 */
export const useTranslations = (useTranslationOptions?: UseTranslationOptions) => {
  const { t, i18n, ...rest } = useTranslation(SURVEY_NAMEPSACE, useTranslationOptions)

  const installCustomTranslations = (translations: TranslationFragment[]) => {
    const resources: { [language: string]: { [code: string]: string } } = {}

    translations.forEach(translation => {
      if (!resources[translation.language.code]) {
        resources[translation.language.code] = {}
      }
      resources[translation.language.code][translation.englishText] = translation.text
    })

    Object.entries(resources).forEach(([lang, langTranslations]) => {
      i18n.addResourceBundle(lang, SURVEY_NAMEPSACE, langTranslations)
    })
  }

  return { t: t as TranslationFn, i18n, installCustomTranslations, ...rest }
}
