import ReactGA from 'react-ga'

import { GA_CODE } from '.'

const getClient = () => {
  if (GA_CODE) {
    ReactGA.initialize(GA_CODE, {
      debug: false,
    })
    return ReactGA
  }
  return null
}

export default getClient()
