import React from 'react'

import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import cn from 'classnames'

import Label from 'components/Survey/Blocks/Label'
import { LanguageContext, useTranslations, TranslationKey } from 'locales'
import { desktopStyle } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& >div': {
        display: 'flex',
        flexDirection: 'column',
        '& >button': {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: theme.spacing(1),
          border: `1px solid ${theme.palette.common.brandBlue}`,
        },
      },
    },
    horizontalContainer: {
      ...desktopStyle({
        '& >div': {
          display: 'flex',
          flexDirection: 'row',
          '& >button': {
            lineHeight: 1.2,
            height: 80,
            width: 95,
            flexDirection: 'column',
            alignItems: 'center',
            border: `1px solid ${theme.palette.common.brandBlue}`,
            marginRight: theme.spacing(2),
          },
        },
      }),
    },
    verticalQuestionDisplayContainer: {
      '& >div': {
        display: 'flex',
        flexDirection: 'column',
        '& >button': {
          width: '100%',
          padding: 0,
          marginLeft: 0,
          marginRight: 0,
          height: theme.spacing(7),
          '& >span': {
            fontSize: '1.6rem',
          },
        },
      },
    },
    unselected: {
      color: theme.palette.common.brandBlue,
      backgroundColor: theme.palette.common.fadedGrey,
      '&:hover': {
        backgroundColor: theme.palette.common.fadedBlue,
      },
    },
    selected: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.brandBlue,
      '&:hover': {
        backgroundColor: theme.palette.common.brandBlue,
      },
    },
    multiselectLabel: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      '& >div': {
        flex: 1,
        textAlign: 'start',
      },
    },
    dropdown: {
      height: theme.spacing(6),
      color: theme.palette.common.brandBlue,
      backgroundColor: theme.palette.common.fadedGrey,
      border: `1px solid ${theme.palette.common.brandBlue}`,
      borderRadius: 4,
      '& >div': {
        padding: theme.spacing(1),
      },
    },
  }),
)

// We are saving multiple responses as a single string separated with commas.
// Therefore, to manipulate the UI we are converting to a list
const getMultiselectResponse = (response: string) => (response ? response.split(',') : [])

const setMultiselectResponse = (responses: string[]) => responses.join(',')

const isSelected = (isMultiselect: boolean, code: string, response?: string | number) => {
  if (isMultiselect) {
    const responses = getMultiselectResponse(String(response || ''))
    return responses.includes(code)
  }
  return response === code
}

const MAX_CHOICES_DISPLAY = 5
interface Props {
  question: { text: string; benchmarkCode?: string | null }
  choices: { text: TranslationKey; code: string; benchmarkCode?: string }[]
  onChange: (response: string) => void
  response?: string | number
  displayHorizontal: boolean
  isMultiselect: boolean
}
const MultipleChoiceBlock: React.FC<Props> = ({
  question,
  choices: initialChoices,
  onChange,
  response,
  displayHorizontal,
  isMultiselect,
}) => {
  const classes = useStyles()
  const { t } = useTranslations()

  let handleOnChange = onChange
  if (isMultiselect) {
    handleOnChange = (code: string) => {
      const selected = isSelected(isMultiselect, code, response)
      const responses = getMultiselectResponse(String(response || ''))
      const newResponses = selected ? responses.filter(c => c !== code) : [...responses, code]
      onChange(setMultiselectResponse(newResponses))
    }
  }

  const choices = initialChoices.map(c => ({ ...c, text: t(c.text) }))

  // Some languages such as Armenian and Russian require that the question blocks are vertical.
  const langRequiresVerticalQuestions = (languageCode: string) =>
    ['arm', 'rus'].includes(languageCode)

  return (
    <LanguageContext.Consumer>
      {({ languageCode }) => (
        <div
          className={cn(
            classes.container,
            {
              [classes.horizontalContainer]: displayHorizontal,
            },
            {
              [classes.verticalQuestionDisplayContainer]: langRequiresVerticalQuestions(
                languageCode,
              ),
            },
          )}
        >
          <Label text={question.text} id={question.benchmarkCode} />
          <div id="options">
            {choices.length > MAX_CHOICES_DISPLAY ? (
              <FormControl variant="filled">
                <Select
                  // Make sure to rerender the select on each question so that the state is completely wiped.
                  // This solves an issue when a multiselect checkbox is rendered after a multiple choice select,
                  // where the state of `isMultiselect` was updating before the state of the response.
                  key={question.text}
                  id={`select${
                    choices.find(choice => response === choice.code)?.benchmarkCode || ''
                  }`}
                  className={classes.dropdown}
                  value={isMultiselect ? getMultiselectResponse(String(response || '')) : response}
                  onChange={e => {
                    const value: unknown = (e.target as HTMLInputElement).value
                    return isMultiselect
                      ? onChange(setMultiselectResponse(value as string[]))
                      : onChange(value as string)
                  }}
                  name="multipleChoice"
                  displayEmpty
                  disableUnderline
                  multiple={isMultiselect}
                  renderValue={newResponse => {
                    if (isMultiselect) {
                      return (
                        choices
                          .filter(choice => (newResponse as string[]).includes(choice.code))
                          .map(choice => choice.text)
                          .join(', ') || (
                          <Typography color="textSecondary">
                            {t('Select all that apply')}
                          </Typography>
                        )
                      )
                    }
                    const theChoice = choices.find(choice => newResponse === choice.code)
                    if (!theChoice) {
                      return <Typography color="textSecondary">{t('Please choose one')}</Typography>
                    }
                    return theChoice.text
                  }}
                >
                  {choices.map(choice => (
                    <MenuItem key={choice.code} value={choice.code} id={choice.benchmarkCode}>
                      {isMultiselect && (
                        <Checkbox checked={isSelected(isMultiselect, choice.code, response)} />
                      )}
                      {choice.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <>
                {isMultiselect && (
                  <Typography color="textSecondary">{t('Select all that apply')}</Typography>
                )}
                {choices.map(({ benchmarkCode, code, text }) => {
                  const selected = isSelected(isMultiselect, code, response)
                  // We're using text for Custom Questions as they don't have a benchmarkCode
                  const id = benchmarkCode || text.split(' ').join('')
                  return (
                    <Button
                      id={id + (selected ? '-selected' : '')}
                      key={code}
                      className={selected && !isMultiselect ? classes.selected : classes.unselected}
                      onClick={() => handleOnChange(code)}
                    >
                      <div className={cn({ [classes.multiselectLabel]: isMultiselect })}>
                        {isMultiselect && (
                          <Checkbox checked={isSelected(isMultiselect, code, response)} />
                        )}
                        <ListItemText primary={text} />
                      </div>
                    </Button>
                  )
                })}
              </>
            )}
          </div>
        </div>
      )}
    </LanguageContext.Consumer>
  )
}

export default MultipleChoiceBlock
