import React from 'react'

import { Button, withStyles, createStyles, Theme, WithStyles } from '@material-ui/core'
import cn from 'classnames'

import { TranslationKey, useTranslations } from 'locales'
import { desktopStyle } from 'utils'

const styles = (theme: Theme) =>
  createStyles({
    buttonContainer: {
      position: 'fixed',
      bottom: '12%',
      width: '90%',
      ...desktopStyle({
        bottom: '15%',
        position: 'static',
        marginTop: '5%',
        width: '100%',
      }),
      // Make sure the height is constant when the button is revealed
      height: 38,
    },
    smallButton: {
      ...desktopStyle({
        width: 150,
      }),
      width: '100%',
    },
    bigButton: {
      ...desktopStyle({
        width: 200,
      }),
      width: '100%',
    },
    disabledColor: {
      backgroundColor: theme.palette.common.disabledBlue,
    },
  })

interface Props extends WithStyles<typeof styles> {
  goNext(): void
  disabled?: boolean
  buttonStyle?: string
  nextLabel?: TranslationKey
  useSmallButton?: boolean
  color?: 'default' | 'primary' | 'secondary'
}

const NextButton: React.FC<Props> = ({
  classes,
  goNext,
  buttonStyle,
  color,
  nextLabel = 'Continue',
  useSmallButton = true,
  disabled = false,
}) => {
  const { t } = useTranslations()

  return (
    <div className={buttonStyle || classes.buttonContainer}>
      <Button
        variant="text"
        color={color || 'primary'}
        onClick={goNext}
        disabled={disabled}
        className={cn({
          [classes.smallButton]: useSmallButton,
          [classes.bigButton]: !useSmallButton,
          [classes.disabledColor]: disabled,
        })}
      >
        {t(nextLabel)}
      </Button>
    </div>
  )
}

export default withStyles(styles)(NextButton)
