import React, { useState } from 'react'

import {
  makeStyles,
  createStyles,
  TextField,
  Theme,
  IconButton,
  Typography,
  Snackbar,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import FileCopy from '@material-ui/icons/FileCopyOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import NextButton from 'components/lib/NextButton'
import Label from 'components/Survey/Blocks/Label'
import { useTranslations } from 'locales'
import { desktopStyle } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notification: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: 4,
      '& >p': {
        paddingTop: 4,
      },
    },
    textField: {
      marginTop: 20,
      overflowY: 'scroll',
      maxHeight: 270,
      width: '100%',
      ...desktopStyle({
        maxHeight: 320,
      }),
      '& >div>textarea': {
        color: theme.palette.common.black,
      },
    },
    clipboardButton: {
      marginTop: theme.spacing(4),
    },
    copyComment: {
      display: 'flex',
      color: theme.palette.common.mediumGrey,
      '& >p': {
        paddingLeft: theme.spacing(1),
      },
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.common.black,
      },
    },
  }),
)

type Props = {
  response?: string
  goNext(): void
}
const CopyTestimonial: React.FC<Props> = ({ response, goNext }) => {
  const { t } = useTranslations()

  const [showNotification, setShowNotification] = useState(false)
  const classes = useStyles()
  if (!response) return null
  return (
    <>
      <Label
        text={t('Before you go to the review site, do you want to copy this comment to use?')}
      />
      <TextField className={classes.textField} value={response} multiline disabled rowsMax={5} />
      <div className={classes.clipboardButton}>
        <CopyToClipboard
          text={response}
          onCopy={() => {
            setShowNotification(true)
            setTimeout(() => setShowNotification(false), 4000)
          }}
        >
          <div className={classes.copyComment}>
            <FileCopy /> <Typography>{t('Copy Comment')}</Typography>
          </div>
        </CopyToClipboard>
      </div>
      <NextButton goNext={goNext} nextLabel="Next" />
      <Snackbar
        message={
          <div className={classes.notification}>
            <Typography>{t('Copied to clipboard.')}</Typography>
            <Typography>{t('On the next page, paste this text to save time.')}</Typography>
          </div>
        }
        open={showNotification}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setShowNotification(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  )
}

export default CopyTestimonial
