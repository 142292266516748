import { WALLE_HOST } from 'config'

export const URLS = {
  // These URLs are the base for routing within the project
  PORTAL: '/:slug',
  PREVIEW: 'preview',
  SURVEY: `survey`,
  // To support old URLs from employee surveys that are still in use.
  DEPRECATED_SURVEY: 's',
  OPEN_LINK_SURVEY: `public`,
  OPEN_LINK_SURVEY_PREVIEW: `public-preview`,
  // These urls are paths in the survey, they are isolated so they can be used in
  // in both surveys and preview (ex. /survey/:id/end or /preview/:id/end)
  WELCOME: `welcome`,
  SELECT_LANGUAGE: `language`,
  COMPLETE: `end`,
  TAKE_SURVEY: `take`,
}

export const ERROR_MAPPING: { [key: string]: string } = {
  PARTICIPANT_NOT_FOUND: 'No participant with this survey code found.',
  SURVEY_SUBMITTED: `This survey has already been submitted.
        If you are not the individual who completed the survey please reach out
        to your local survey administration contact.`,
}

export const RESIDENT_PRODUCT_TYPE = 'resident'

export const WALLE_URLS = {
  SETTINGS: {
    LOCATIONS: `${WALLE_HOST}/org-settings/values`,
  },
}

export enum SurveyDisplayStrategy {
  QUESTION_BY_QUESTION = 'QUESTION_BY_QUESTION',
  QUESTION_BATCH = 'QUESTION_BATCH',
}
