import React from 'react'

import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import * as Sentry from '@sentry/react'

const FallbackComponent: React.FC = () => {
  return (
    <Typography variant="body2" style={{ padding: 20, display: 'flex', alignItems: 'center' }}>
      <ErrorIcon color="error" style={{ paddingRight: 4 }} />
      Oops, something went wrong! We have been alerted and will fix it! If you need to access this
      page right away trying refreshing or contact your survey administrator.
    </Typography>
  )
}

const fallBack = <FallbackComponent />

// https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
export default function withErrorHandler<Props extends Object, _>(
  Component: React.ComponentType<Props>,
) {
  function withHandler(props: Props) {
    return (
      <Sentry.ErrorBoundary fallback={fallBack}>
        <Component {...props} />
      </Sentry.ErrorBoundary>
    )
  }

  return withHandler
}
