import React from 'react'

import { makeStyles, Theme, createStyles } from '@material-ui/core'
import Lottie from 'react-lottie'

import loadingAnimation from 'assets/animations/loading.json'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: theme.palette.common.white,
      position: 'fixed',
      height: '100%',
      width: '100%',
      background: theme.palette.common.brandBlue,
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

const LoadingBlock: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Lottie
        options={{
          animationData: loadingAnimation,
          loop: true,
        }}
        height={120}
        width={120}
      />
    </div>
  )
}

export default LoadingBlock
