import React, { useState } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { useTranslations, LanguageContext } from 'locales'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.white,
    },
  }),
)

type Props = {
  languages: { code: string; nameTranslated: string }[]
}

const LanguageMenu: React.FC<Props> = ({ languages }) => {
  const classes = useStyles()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const { t } = useTranslations()

  return (
    <LanguageContext.Consumer>
      {({ changeLanguageCode, languageCode }) => (
        <div>
          {languages.length > 1 && (
            <>
              <Button
                id="changeLanguage"
                className={classes.button}
                onClick={e => setAnchor(e.currentTarget as HTMLElement)}
              >
                {t('Change Language')} <ArrowDropDownIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
              >
                {languages.map(({ code, nameTranslated }) => (
                  <MenuItem
                    id={`${code}ChangeLanguage`}
                    key={code}
                    selected={code === languageCode}
                    onClick={() => {
                      changeLanguageCode(code)
                      setAnchor(null)
                    }}
                  >
                    {nameTranslated}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </div>
      )}
    </LanguageContext.Consumer>
  )
}

export default LanguageMenu
