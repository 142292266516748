import React, { ReactElement } from 'react'

import { Typography, makeStyles, createStyles, Theme } from '@material-ui/core'

import { ReactComponent as ErrorBottomIcon } from 'assets/img/error-bottom-icon.svg'
import { ReactComponent as ErrorTopIcon } from 'assets/img/error-top-icon.svg'
import { useTranslations } from 'locales'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      background: theme.palette.common.fadedGrey,
      '& >p': {
        padding: 30,
      },
    },
    errorBottomIcon: {
      marginTop: 10,
      marginBottom: 50,
    },
  }),
)

const ErrorState: React.FC<{ message?: string | ReactElement }> = ({ message }) => {
  const classes = useStyles()
  const { t } = useTranslations()

  return (
    <div className={classes.container}>
      <ErrorTopIcon />
      <ErrorBottomIcon className={classes.errorBottomIcon} />
      <Typography variant="h3" color="primary">
        {/* message is expected to be translated */}
        {message || t('Oops! Something went wrong')}
      </Typography>
      {!message && <Typography>{t('Try refreshing the page or come back later.')}</Typography>}
    </div>
  )
}

export default ErrorState
