// These get replaced with entrypoint.sh when static docker image starts
const ENV: { [index: string]: string } = {
  // eslint-disable-next-line
  GRAPHQL_ENDPOINT: '${REACT_APP_GRAPHQL_ENDPOINT}',
  // eslint-disable-next-line
  SENTRY_DSN: '${REACT_APP_SENTRY_DSN}',
  // eslint-disable-next-line
  RELEASE_VERSION: '${REACT_APP_RELEASE_VERSION}',
  // eslint-disable-next-line
  GA_CODE: '${REACT_APP_SURVEY_GOOGLE_ANALYTICS_CODE}',
  // eslint-disable-next-line
  WALLE_HOST: '${REACT_APP_WALLE_HOST}',
  // eslint-disable-next-line
  STATIC_URL: '${REACT_APP_STATIC_URL}',
  // eslint-disable-next-line
  FINGERPRINT_API_KEY: '${REACT_APP_FINGERPRINT_API_KEY}',
  // eslint-disable-next-line
  FINGERPRINT_ENDPOINT_SURVEY: '${REACT_APP_FINGERPRINT_ENDPOINT_SURVEY}',
  // eslint-disable-next-line
  RESIDENT_SURVEY_HOST: '${REACT_APP_RESIDENT_SURVEY_HOST}',
  // eslint-disable-next-line
  SURVEY_HOST: '${REACT_APP_SURVEY_HOST}',
}

const getEnv = (key: keyof typeof ENV, defaultValue: string) => {
  let value = ENV[key]
  if (value.indexOf('${') === 0) {
    // Running in dev version
    value = process.env[`REACT_APP_${key}`] || ''
  }
  return value || defaultValue
}

const GRAPHQL_ENDPOINT = getEnv('GRAPHQL_ENDPOINT', '')
const SENTRY_DSN = getEnv('SENTRY_DSN', '')
const RELEASE_VERSION = getEnv('RELEASE_VERSION', '')
const GA_CODE = getEnv('GA_CODE', '')
const WALLE_HOST = getEnv('WALLE_HOST', '')
const SURVEY_HOST = getEnv('SURVEY_HOST', '')
const RESIDENT_SURVEY_HOST = getEnv('RESIDENT_SURVEY_HOST', '')
const STATIC_URL = getEnv('STATIC_URL', '')
const FINGERPRINT_API_KEY = getEnv('FINGERPRINT_API_KEY', '')
const FINGERPRINT_ENDPOINT = getEnv('FINGERPRINT_ENDPOINT_SURVEY', '')

export {
  GRAPHQL_ENDPOINT,
  SENTRY_DSN,
  RELEASE_VERSION,
  GA_CODE,
  WALLE_HOST,
  SURVEY_HOST,
  RESIDENT_SURVEY_HOST,
  STATIC_URL,
  FINGERPRINT_API_KEY,
  FINGERPRINT_ENDPOINT,
}
