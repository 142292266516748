import React from 'react'

import { makeStyles, createStyles, TextField } from '@material-ui/core'

import Label from 'components/Survey/Blocks/Label'
import { useStyles as useShortAnswerStyles } from 'components/Survey/Blocks/ShortAnswerBlock'
import { TranslatedQuestion } from 'components/Survey/Survey'
import { useTranslations, TranslationKey } from 'locales'

export const useStyles = makeStyles(() =>
  createStyles({
    textsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      '& >div': {
        width: '45%',
      },
    },
  }),
)

interface Props {
  question: TranslatedQuestion
  multiline: boolean
  onChange1: (response: string) => void
  onChange2: (response: string) => void
  response1?: string
  response2?: string
  subtext?: string
  placeholder1?: TranslationKey
  placeholder2?: TranslationKey
}

const DoubleShortAnswerBlock: React.FC<Props> = ({
  question,
  onChange1,
  onChange2,
  response1,
  response2,
  multiline,
  placeholder1,
  placeholder2,
}) => {
  const classes = { ...useStyles(), ...useShortAnswerStyles() }
  const { t } = useTranslations()

  return (
    <>
      <Label text={question.text} id={question.benchmarkCode} />
      <div className={classes.textsWrapper}>
        <TextField
          id={`${question.benchmarkCode}1`}
          key={`${question.code}1`}
          value={response1}
          onChange={e => onChange1((e.target as HTMLInputElement).value)}
          multiline={multiline}
          name={question.text || ''}
          className={classes.textField}
          placeholder={placeholder1 && t(placeholder1)}
        />
        <TextField
          id={`${question.benchmarkCode}2`}
          key={`${question.code}2`}
          value={response2}
          onChange={e => onChange2((e.target as HTMLInputElement).value)}
          multiline={multiline}
          name={question.text || ''}
          className={classes.textField}
          placeholder={placeholder2 && t(placeholder2)}
        />
      </div>
    </>
  )
}

export default DoubleShortAnswerBlock
