import React from 'react'

import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core'
import { format } from 'date-fns'

import { ReactComponent as Icon } from 'assets/img/survey-not-yet-live-icon.svg'
import { useTranslations } from 'locales'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: theme.palette.common.white,
      position: 'fixed',
      height: '100%',
      width: '100%',
      background: theme.palette.common.brandBlue,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& >div >svg': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    startDate: {
      color: '#A7EAFF',
    },
  }),
)

interface Props {
  startDate: Date
}

const SurveyNotLive: React.FC<Props> = ({ startDate }) => {
  const classes = useStyles()
  const { t } = useTranslations()

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="h4">{t('This survey is not yet live')}</Typography>
        <Icon />
        <Typography>{t("It's scheduled to go live on:")}</Typography>
        <Typography className={classes.startDate}>
          {format(new Date(startDate), 'MMMM Do, YYYY')} at {format(new Date(startDate), 'h:mm a')}
        </Typography>
        <Typography>{t('Check back after this date to take the survey.')}</Typography>
      </div>
    </div>
  )
}

export default SurveyNotLive
