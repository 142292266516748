import React from 'react'

import { makeStyles, CircularProgress, Theme } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  progress: {
    margin: spacing(2),
  },
}))

interface Props {
  size?: number
}

const Loader: React.FC<Props> = ({ size = 50 }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.progress} color="secondary" size={size} />
    </div>
  )
}

export default Loader
