import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import { ReactComponent as StarsIcon } from 'assets/img/stars.svg'
import AllSetScreen from 'components/CompletionScreens/AllSetScreen'
import CopyTestimonial from 'components/CompletionScreens/CopyTestimonial'
import ThankYouScreen from 'components/CompletionScreens/ThankYouScreen'
import useGlobalStyles from 'components/lib/GlobalStyles'
import NavBar from 'components/lib/NavBar'
import TransitionBlock from 'components/Survey/Blocks/TransitionBlock'
import { TranslatedQuestion } from 'components/Survey/Survey'
import ReactGA from 'config/googleAnalytics'
import { OnlineReviewSiteEnum, LanguageType } from 'generated/graphql'
import { useTranslations } from 'locales'
import { ReviewSites } from 'utils/types'

const useStyles = makeStyles(theme => ({
  onlineReview: {
    textAlign: 'center',
    '& >svg': {
      width: '30%',
      margin: theme.spacing(4),
    },
  },
  onlineReviewButtons: {
    textAlign: 'center',
    '& >div': {
      textAlign: 'center',
    },
  },
}))

enum ScreenStep {
  THANK_YOU = 'thankYou',
  ONLINE_REVIEW = 'onlineReview',
  COPY_TESTIMONIAL = 'copyTestimonial',
  ALL_SET = 'allSet',
}

const GOOGLE_URL_PREFIX = 'https://search.google.com/local/writereview?placeid='

type Props = {
  onlineReviewSites?: ReviewSites
  onlineReviewQuestion?: TranslatedQuestion
  testimonialsReviewQuestion?: TranslatedQuestion
  responses: { [key: string]: string }
  isPreview?: boolean
  languages: LanguageType[]
  isPhoneSurvey: boolean
}
const CompletionScreens: React.FC<Props> = ({
  onlineReviewSites,
  onlineReviewQuestion,
  testimonialsReviewQuestion,
  responses,
  isPreview,
  languages,
  isPhoneSurvey,
}) => {
  const { t } = useTranslations()

  const testimonialsResponse =
    testimonialsReviewQuestion && responses[testimonialsReviewQuestion.code]
  const classes = { ...useStyles(), ...useGlobalStyles() }
  const [screen, setScreen] = useState(ScreenStep.THANK_YOU)
  const onAction = (action: 'next' | 'back' | 'skip') => {
    switch (screen) {
      case ScreenStep.THANK_YOU:
        switch (action) {
          case 'next':
            return setScreen(ScreenStep.ONLINE_REVIEW)
        }
        break
      case ScreenStep.ONLINE_REVIEW:
        switch (action) {
          case 'next': {
            if (testimonialsResponse) {
              ReactGA?.event({
                action: 'viewCopyTestimonials',
                category: 'SurveyCompletion',
              })
              return setScreen(ScreenStep.COPY_TESTIMONIAL)
            }
            // iFrame security prevents us from redirecting in the preview
            if (isPreview) return null
            ReactGA?.event({
              action: 'redirectToGoogleReview',
              category: 'SurveyCompletion',
            })
            window.location.href = googleUrl
            return null
          }
          case 'back':
            return setScreen(ScreenStep.THANK_YOU)
          case 'skip':
            ReactGA?.event({
              action: 'declineOnlineReview',
              category: 'SurveyCompletion',
            })
            return setScreen(ScreenStep.ALL_SET)
        }
        break
      case ScreenStep.COPY_TESTIMONIAL:
        switch (action) {
          case 'next':
            // iFrame security prevents us from redirecting in the preview
            if (isPreview) return
            ReactGA?.event({
              action: 'redirectToGoogleReview',
              category: 'SurveyCompletion',
            })
            window.location.href = googleUrl
            return
          case 'back':
            return setScreen(ScreenStep.ONLINE_REVIEW)
        }
        break
      case ScreenStep.ALL_SET:
        switch (action) {
          case 'back':
            return setScreen(ScreenStep.ONLINE_REVIEW)
        }
    }
  }
  const googlePlaceId = onlineReviewSites?.find(
    site => site.reviewSiteName === OnlineReviewSiteEnum.GOOGLE,
  )?.externalId
  if (!onlineReviewQuestion || !googlePlaceId || isPhoneSurvey) {
    return <ThankYouScreen />
  }
  const googleUrl = GOOGLE_URL_PREFIX + googlePlaceId
  let Screen
  switch (screen) {
    case ScreenStep.THANK_YOU:
      // Don't render the ThankYouScreen in the container since we don't allow "Back".
      return <ThankYouScreen goNext={() => onAction('next')} />
    case ScreenStep.ALL_SET:
      Screen = <AllSetScreen />
      break
    case ScreenStep.ONLINE_REVIEW:
      Screen = (
        <TransitionBlock
          label={
            <div className={classes.onlineReview}>
              <div>{t('Will you take a minute to leave an online review?')}</div>
              <StarsIcon />
              <div>{t('Sharing your experience will help other seniors and their families.')}</div>
            </div>
          }
          nextLabel="Sure!"
          goNext={() => onAction('next')}
          buttonGroupStyle={classes.onlineReviewButtons}
          skipLabel="No, thanks."
          onSkip={() => onAction('skip')}
        />
      )
      break
    case ScreenStep.COPY_TESTIMONIAL:
      Screen = (
        <div className={classes.questionBlock}>
          <CopyTestimonial response={testimonialsResponse} goNext={() => onAction('next')} />
        </div>
      )
      break
  }
  return (
    <>
      <NavBar goBack={() => onAction('back')} languages={languages} />
      <div className={classes.screenContainer}>{Screen}</div>
    </>
  )
}

export default CompletionScreens
