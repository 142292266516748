import React, { useState } from 'react'

import { makeStyles, Typography, Button, createStyles, TextField, Theme } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'

import bgImage from 'assets/img/chess.jpg'
import ErrorState from 'components/ErrorState'
import Loader from 'components/lib/Loader'
import ReactGA from 'config/googleAnalytics'
import {
  useOrganizationBySlugQuery,
  useCodeLookupMutation,
  CodeLookupMutation,
  OrganizationBySlugQuery,
  ParticipantSourceTypeInput,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { useTranslations } from 'locales'
import { desktopStyle, getProductTypeFromUrl } from 'utils'
import { ERROR_MAPPING, URLS } from 'utils/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      html: {
        height: '100%',
      },
      body: {
        minHeight: '100%',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${bgImage})`,
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
      marginTop: '5%',
      marginLeft: '5%',
      marginRight: '5%',
      ...desktopStyle({
        marginLeft: '25%',
        marginRight: '25%',
      }),
    },
    input: {
      backgroundColor: 'white',
      marginTop: '10%',
      marginBottom: '10%',
    },
    submitButton: {
      border: 0,
      marginBottom: '10%',
    },
    errors: {
      marginBottom: '10%',
    },
    surveyOptionButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  }),
)

const setAnalyticsDimensions = (
  analyticsIdentifiers: OrganizationBySlugQuery['organizationAnalyticsIdentifiers'],
  surveyProductType: SurveyProductTypeEnum,
) => {
  if (ReactGA && analyticsIdentifiers) {
    ReactGA.set({
      dimension1: analyticsIdentifiers.isTestAccount,
      dimension2: analyticsIdentifiers.orgName,
      dimension3:
        surveyProductType === SurveyProductTypeEnum.RESIDENT
          ? analyticsIdentifiers.residentSolutionName
          : analyticsIdentifiers.solutionName,
      dimension7: ParticipantSourceTypeInput.L,
    })
  }
}

type SurveyOptions = NonNullable<NonNullable<CodeLookupMutation['codeLookup']>['surveys']>
const PortalScreen: React.FC = () => {
  const { t } = useTranslations()
  const { slug } = useParams<{ slug: string }>()
  const history = useHistory()
  const classes = useStyles()
  const surveyProductType = getProductTypeFromUrl()
  const result = useOrganizationBySlugQuery({
    variables: { slug, surveyProductType },
  })
  const [surveyCode, setSurveyCode] = useState('')
  // When a survey code can be used to take multiple live surveys.
  const [surveyOptions, setSurveyOptions] = useState<SurveyOptions>([])
  const [errors, setErrors] = useState<Array<string | undefined | null>>([])
  const [codeLookup, { loading }] = useCodeLookupMutation()
  if (result.loading) {
    return <Loader />
  }
  if (!result.data?.organizationBySlug?.uuid) {
    return <ErrorState message={t('Error: Organization not found')} />
  }
  const org = result.data.organizationBySlug

  const portalSource = ParticipantSourceTypeInput.L

  const handleCodeSubmit = async () => {
    const lookupResult = await codeLookup({
      variables: {
        organizationUuid: org.uuid || '',
        code: surveyCode,
        surveyProductType,
      },
    })
    if (!lookupResult.data?.codeLookup) {
      return
    }
    const { errors: lookupErrors, surveys } = lookupResult.data?.codeLookup
    if (lookupErrors) {
      setErrors(lookupErrors)
      return
    }
    const activeSurveys = surveys?.filter(s => !s.surveySubmitted)
    if (!activeSurveys?.length) {
      setErrors(['SURVEY_SUBMITTED'])
      return
    }
    if (activeSurveys.length > 1) {
      setSurveyOptions(activeSurveys)
      return
    }
    setAnalyticsDimensions(result.data?.organizationAnalyticsIdentifiers, surveyProductType)
    history.push(`${URLS.SURVEY}/${activeSurveys[0].participantId}/${portalSource}`)
  }
  if (surveyOptions.length > 1) {
    return (
      <div className={classes.container}>
        <Typography variant="h3">
          {t('There are currently')} {surveyOptions.length} {t('open surveys.')}
          <br />
          <br />
          {t("Please choose which one you'd like to take at this moment.")}
          <br />
          <br />
        </Typography>
        {surveyOptions.map(survey => (
          <Button
            key={survey.surveyName}
            className={classes.surveyOptionButton}
            color="secondary"
            onClick={() => history.push(`${URLS.SURVEY}/${survey.participantId}/${portalSource}`)}
          >
            {survey.surveyName}
          </Button>
        ))}
      </div>
    )
  }
  const { surveyCodeName, helpText } = {
    [SurveyProductTypeEnum.EMPLOYEE]: {
      surveyCodeName: org.employeeSurveyCodeName,
      helpText: org.employeeHelpText,
    },
    [SurveyProductTypeEnum.RESIDENT]: {
      surveyCodeName: org.residentSurveyCodeName,
      helpText: org.residentHelpText,
    },
  }[surveyProductType]
  return (
    <div className={classes.container}>
      <Typography variant="h3">
        {t('Please submit')} {surveyCodeName} {t('to take your survey:')}
      </Typography>
      <TextField
        value={surveyCode}
        variant="outlined"
        className={classes.input}
        onChange={e => setSurveyCode((e.target as HTMLInputElement).value)}
      />
      {loading ? (
        <Loader />
      ) : (
        <Button
          className={classes.submitButton}
          variant="text"
          color="secondary"
          onClick={handleCodeSubmit}
        >
          {t('Submit')}
        </Button>
      )}
      {errors.length > 0 && (
        <div className={classes.errors}>
          {errors.map((e, idx) => (
            <Typography color="error" key={`${e}${idx}`}>
              {(e && ERROR_MAPPING[e]) ||
                `${t(`Oops! We had an error fetching this`)} ${surveyCodeName}.`}
            </Typography>
          ))}
        </div>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: helpText || '',
        }}
      />
    </div>
  )
}

export default PortalScreen
