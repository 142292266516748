import React from 'react'

import { LinearProgress, Typography } from '@material-ui/core'

import useGlobalStyles from 'components/lib/GlobalStyles'
import NextButton from 'components/lib/NextButton'
import { PublicQuestion } from 'components/OpenLinkSurveyContainer'
import DoubleShortAnswerBlock from 'components/Survey/Blocks/DoubleShortAnswerBlock'
import { useStyles, QuestionBlock } from 'components/Survey/Survey'
import { BenchmarkCodeType } from 'generated/graphql'

type PersonalInfoQuestionProps = {
  question: PublicQuestion
  goNext(): void
  onResponseChange(questionBenchmarkCode: string, response: string): void
  isLastQuestion: boolean
  completionPercentage: number
  responses: { [code: string]: string }
}
const PersonalInfoQuestion: React.FC<PersonalInfoQuestionProps> = ({
  question,
  goNext,
  onResponseChange,
  isLastQuestion,
  completionPercentage,
  responses,
}) => {
  const classes = { ...useStyles(), ...useGlobalStyles() }

  let goNextDisabled = false
  let questionBlock
  if (question.benchmarkCode === BenchmarkCodeType.NAME) {
    goNextDisabled =
      !(responses.firstName || '').replace(/ /g, '') ||
      !(responses.lastName || '').replace(/ /g, '')
    questionBlock = (
      <DoubleShortAnswerBlock
        question={question}
        multiline={false}
        response1={responses.firstName}
        response2={responses.lastName}
        onChange1={(response: string) => onResponseChange('firstName', response)}
        onChange2={(response: string) => onResponseChange('lastName', response)}
        placeholder1="First Name"
        placeholder2="Last Name"
      />
    )
  } else if (question.benchmarkCode === BenchmarkCodeType.CLIENT_NAME) {
    goNextDisabled =
      !(responses.clientFirstName || '').replace(/ /g, '') ||
      !(responses.clientLastName || '').replace(/ /g, '')
    questionBlock = (
      <DoubleShortAnswerBlock
        question={question}
        multiline={false}
        response1={responses.clientFirstName}
        response2={responses.clientLastName}
        onChange1={(response: string) => onResponseChange('clientFirstName', response)}
        onChange2={(response: string) => onResponseChange('clientLastName', response)}
        placeholder1="Family Member First Name"
        placeholder2="Family Member Last Name"
      />
    )
  } else {
    const code = question.benchmarkCode || ''
    goNextDisabled = !responses[code]
    questionBlock = (
      <QuestionBlock
        question={question}
        goNext={goNext}
        response={responses[code]}
        onChange={(response: string) => {
          onResponseChange(code, response)
        }}
      />
    )
  }

  return (
    <>
      <div className={classes.questionBlock}>
        {questionBlock}
        <NextButton
          nextLabel={isLastQuestion ? 'Submit' : 'Next'}
          goNext={goNext}
          color="primary"
          disabled={goNextDisabled}
        />
      </div>
      <div className={classes.footer}>
        <div className={classes.progressBlock}>
          <LinearProgress
            className={classes.progressBar}
            classes={{
              colorPrimary: classes.progressBarColor,
              barColorPrimary: classes.progressBarLineColor,
            }}
            color="primary"
            variant="determinate"
            value={completionPercentage}
          />
          <Typography variant="caption">{completionPercentage}%</Typography>
        </div>
      </div>
    </>
  )
}

export default PersonalInfoQuestion
