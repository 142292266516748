import React from 'react'

import { Typography, makeStyles, createStyles, Theme } from '@material-ui/core'
import Lottie from 'react-lottie'

import successAnimation from 'assets/animations/success.json'
import NextButton from 'components/lib/NextButton'
import { useTranslations } from 'locales'
import { desktopStyle } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
      position: 'fixed',
      background: 'linear-gradient(103.18deg, #3F7FDF 12.63%, #1B46B8 94.41%)',
      height: '100%',
      width: '100%',
      '& >p, & >h4': {
        color: theme.palette.common.white,
      },
      paddingLeft: '5%',
      paddingRight: '5%',
      ...desktopStyle({
        height: '100%',
        paddingLeft: '30%',
        paddingRight: '30%',
      }),
    },
    animation: {
      paddingTop: '25%',
      paddingBottom: 30,
    },
  }),
)

type Props = {
  goNext?(): void
}
const ThankYouScreen: React.FC<Props> = ({ goNext }) => {
  const classes = useStyles()
  const { t } = useTranslations()

  return (
    <div className={classes.container}>
      <div className={classes.animation}>
        <Lottie
          options={{
            animationData: successAnimation,
            loop: false,
          }}
          height={120}
          width={120}
        />
      </div>
      <Typography variant="h4" color="primary">
        {t('Congratulations! Your survey has been submitted, thank you for your feedback!')}
      </Typography>
      {goNext && <NextButton color="secondary" nextLabel="Next" goNext={goNext} />}
    </div>
  )
}

export default ThankYouScreen
