import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { InMemoryCache, IdGetterObj } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import { SentryLink } from 'apollo-link-sentry'
import fetch from 'unfetch'

import { GRAPHQL_ENDPOINT } from 'config'

const TOKEN_KEY = 'auth-token'

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}

export const getAuthHeaders = (headers?: { [key: string]: string }) => {
  // get the authentication token from local storage if it exists
  const token = getToken()
  if (token) {
    // return the headers to the context so httpLink can read them
    const Authorization = `Bearer ${token}`
    return { headers: { ...headers, Authorization } }
  }
  return { headers }
}

const httpLink = createHttpLink({ uri: GRAPHQL_ENDPOINT, fetch })
const authLink = setContext((_, { headers }) => getAuthHeaders(headers))
interface AppObject extends IdGetterObj {
  code?: string
  uuid?: string
}

const errorLink = onError(result => {
  Sentry.withScope(scope => {
    const { networkError, graphQLErrors, operation } = result
    scope.setTransactionName(operation.operationName)
    scope.setContext('Apollo GraphQL Operation', {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions,
    })

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        Sentry.captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}', 'ApolloGraphQLError'],
          contexts: {
            'Apollo GraphQL Error': {
              locations: error.locations,
              path: error.path,
              message: error.message,
              extensions: error.extensions,
            },
          },
        })
      })
    }

    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: 'error',
        fingerprint: ['{{ default }}', '{{ transaction }}', 'ApolloNetworkError'],
        contexts: {
          'Apollo Network Error': {
            error: networkError,
            message: networkError.message,
            extensions: (networkError as any).extensions,
          },
        },
      })
    }
  })
})

const client = new ApolloClient({
  link: from([
    // For more implementation details checkout this article:
    // https://dev.to/namoscato/graphql-observability-with-sentry-34i6
    new SentryLink({
      uri: `${window.location.origin}/graphql/`,
      // The default values used are not great,
      // see our implementation when capturing the errors for more details.
      setTransaction: false,
      setFingerprint: false,
      attachBreadcrumbs: {
        // Including the query adds a lot of noise, having the operation name
        //  should be enough to know what our query was.
        includeQuery: false,
        includeVariables: true,
        includeFetchResult: true,
        includeError: true,
      },
    }),
    errorLink,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: (object: AppObject) => {
      const theId = object.uuid || object.code
      if (theId) {
        return `${object.__typename}-${theId}`
      }
      return undefined
    },
  }),
  connectToDevTools: true,
})

export default client
