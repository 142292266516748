import React from 'react'

import { makeStyles, Button } from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'

import LanguageMenu from 'components/lib/LanguageMenu'
import { LanguageType } from 'generated/graphql'
import { useTranslations } from 'locales'

const useStyles = makeStyles(theme => ({
  backButton: {
    color: 'white',
  },
  navBar: {
    position: 'fixed',
    width: '100%',
    height: 50,
    backgroundColor: theme.palette.common.brandBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 2,
  },
}))

type Props = {
  goBack(): void
  languages: LanguageType[]
  hideGoBack?: boolean
}
const NavBar: React.FC<Props> = ({ goBack, languages, hideGoBack = false }) => {
  const classes = useStyles()
  const { t } = useTranslations()

  return (
    <div className={classes.navBar}>
      {hideGoBack ? (
        <div />
      ) : (
        <Button className={classes.backButton} onClick={goBack}>
          <ArrowLeft /> {t('Back')}
        </Button>
      )}
      <LanguageMenu languages={languages} />
    </div>
  )
}

export default NavBar
